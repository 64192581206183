export default function PageHeading(props) {
    return (
        <div className="page-heading">
            <div className="max-content">
                <div className="page-heading__inner">
                    <h1 className="page-heading__title">{props.title}</h1>
                </div>
            </div>
        </div>
    )
}