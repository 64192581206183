import logo from '../images/logo-metz-hockey-club.png'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'

export default function Nav({ isOverlayVisible, hideNavMobile }) {
    const [openSubmenuId, setOpenSubmenuId] = useState(null);
    const location = useLocation();

    const toggleSubmenu = (id) => {
        setOpenSubmenuId(openSubmenuId === id ? null : id);
    };

    //theses classes work only on md screen size
    //if overlay is visible after click on hamburger, then add "md-is-visible" to overlay
    const getOverlayClass = () => (isOverlayVisible ? "overlay md-is-visible" : "overlay");
    //if overlay is visible after click on hamburger, then add "md-is-visible" to nav
    const getNavClass = () => (isOverlayVisible ? "nav md-is-visible" : "nav");
    //when click on submenu item, add id to toggleSubmenu, then add "md-is-visible" to text link item with children, use to rotate the ::after
    const getSubmenuItemClass = (id) => (openSubmenuId === id ? "nav__list-item text-link-item has-children md-is-visible" : "nav__list-item text-link-item has-children");
    //when click on submenu item, add id to toggleSubmenu, then add "md-is-visible" to nav submenu to display it
    const getSubmenuClass = (id) => (openSubmenuId === id ? "nav__submenu md-is-visible" : "nav__submenu");

    return (
        <>
            <div className={getOverlayClass()} onClick={hideNavMobile}></div>
            <nav className={getNavClass()}>
                <ul className="nav__list max-content">
                    <li className="nav__list-item logo-item">
                        <Link to="/" onClick={hideNavMobile}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </li>
                    <li className="nav__list-item text-link-item">
                        <Link to="/" onClick={hideNavMobile}>
                            Accueil
                        </Link>
                    </li>
                    <li className={getSubmenuItemClass('club')} onClick={() => toggleSubmenu('club')}>
                        Le club
                        <ul className={getSubmenuClass('club')}>
                            <li className="nav__submenu-item">
                                <Link to="/bureau-sportif"
                                    className={`nav__submenu-item__link ${location.pathname === '/bureau-sportif' ? 'active' : ''}`}
                                    onClick={hideNavMobile}>
                                    Bureau sportif
                                </Link>
                            </li>
                            <li className="nav__submenu-item">
                                <Link to="/responsable-equipe"
                                    className={`nav__submenu-item__link ${location.pathname === '/responsable-equipe' ? 'active' : ''}`}
                                    onClick={hideNavMobile}
                                >
                                    Responsables d'équipe
                                </Link>
                            </li>
                            <li className="nav__submenu-item">
                                <Link to="/qui-sommes-nous"
                                    className={`nav__submenu-item__link ${location.pathname === '/qui-sommes-nous' ? 'active' : ''}`}
                                    onClick={hideNavMobile}
                                >
                                    Qui sommes nous ?
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={getSubmenuItemClass('D3')} onClick={() => toggleSubmenu('D3')}>
                        D3
                        <ul className={getSubmenuClass('D3')}>
                            <li className="nav__submenu-item">
                                <Link
                                    to="/equipe-division"
                                    className={`nav__submenu-item__link ${location.pathname === '/equipe-division' ? 'active' : ''}`}
                                    onClick={hideNavMobile}
                                >
                                    Equipe
                                </Link>
                            </li>
                            <li className="nav__submenu-item">
                                <a href="https://www.helloasso.com/associations/metz-hockey-club/boutiques/shop-officiel-metz-hockey-club-2" className="nav__submenu-item__link" onClick={hideNavMobile} target="_blank" rel="noreferrer">
                                    Boutique
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className={getSubmenuItemClass('infospratiques')} onClick={() => toggleSubmenu('infospratiques')}>
                        Infos pratiques
                        <ul className={getSubmenuClass('infospratiques')}>
                            <li className="nav__submenu-item">
                                <Link to="/devenir-licencie"
                                    className={`nav__submenu-item__link ${location.pathname === '/devenir-licencie' ? 'active' : ''}`}
                                    onClick={hideNavMobile}>
                                    Devenir licencié
                                </Link>
                            </li>
                            <li className="nav__submenu-item" onClick={hideNavMobile}>
                                <Link to="/horaires" className={`nav__submenu-item__link ${location.pathname === '/horaires' ? 'active' : ''}`}>
                                    Horaires
                                </Link>
                            </li>
                            <li className="nav__submenu-item" onClick={hideNavMobile}>
                                <Link to="/calendriers" className={`nav__submenu-item__link ${location.pathname === '/calendriers' ? 'active' : ''}`}>
                                    Calendriers
                                </Link>
                            </li>
                            <li className="nav__submenu-item" onClick={hideNavMobile}>
                                <Link to="/location-materiel" className={`nav__submenu-item__link ${location.pathname === '/location-materiel' ? 'active' : ''}`}>
                                    Location matériel enfant
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={getSubmenuItemClass('tournoi')} onClick={() => toggleSubmenu('tournoi')}>
                        Tournoi
                        <ul className={getSubmenuClass('tournoi')}>
                            <li className="nav__submenu-item" onClick={hideNavMobile}>
                                <Link to="/tournoi" className={`nav__submenu-item__link ${location.pathname === '/tournoi' ? 'active' : ''}`}>
                                    Tournoi U13
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav__list-item text-link-item" onClick={hideNavMobile}>
                        <Link to="/partenariat">
                            Partenariat
                        </Link>
                    </li>
                    <li className="nav__list-item text-link-item" onClick={hideNavMobile}>
                        <a href="https://www.helloasso.com/associations/metz-hockey-club/boutiques/shop-officiel-metz-hockey-club-2" target="_blank" rel="noreferrer">
                            Boutique
                        </a>
                    </li>
                    <li className="nav__list-item text-link-item" onClick={hideNavMobile}>
                        <Link to="/vente-aux-encheres">
                            Vente aux enchères
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}