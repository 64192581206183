import { RouterConfig } from './utils/RouterConfig';
import './sass/main.scss';

function App() {
  return (
    <div className="App">
      <RouterConfig />
    </div>
  );
}

export default App;