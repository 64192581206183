import pictureBand from "../images/mhc-5.webp"
import horaires from "../images/horaires.webp"
import Page from '../layouts/Page';

export default function DevenirLicencie() {

    return (
        <Page titlePage="Horaires" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Les Horaires 2024 / 2025</h2>
                <div className='card__img-container'>
                    <img src={horaires} alt="horaires des entrainements de hockey sur glace" />
                </div>
            </main>
        </Page>
    );
}