import pictureBand from "../images/mhc-1.webp"
import tarifs from "../images/tarifs.webp"
import echeancier from "../images/echeancier-paiement.webp"
import Page from '../layouts/Page'

export default function DevenirLicencie() {

    return (
        <Page titlePage ="Devenir licencié" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Les Avantages</h2>
                <ul className="card__list">
                    <li className="card__list-item">–15% sur la boutique de la patinoire (hors textile).</li>
                    <li className="card__list-item">Tarif préférentiel “club” pour toute entrée Séance Publique de l’Ice Arena Metz.</li>
                    <li className="card__list-item">Entrée gratuite pour assister aux matchs à domicile de l’équipe D3 du Metz Hockey Club.</li>
                    <li className="card__list-item">Tarif préférentiel pour les stages perfectionnement enfants/ados organisés pendant les vacances scolaires.</li>
                </ul>
                <h2 className='card__title'>Les Catégories</h2>
                <p>
                    Le tableau ci dessous donne les catégories en fonction des années de naissance.
                    Il rapelle également les règles de sur-classement et sous-classements.
                    Ces règles sont différentes pour les filles et les garçons.
                </p>
                <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTjHEnyhGYRw4jO_AO2yFePwSBtQXv8UwTBa5WgtCDFgpgqXB75v3pFsUQcMR4OSOOruffr8mDfR6vy/embed?start=false&loop=false&delayms=3000" title="categorie-licence" frameBorder="0" width="100%" height="400"></iframe>
                <h2 className='card__title'>Les Tarifs</h2>
                <div className='card__img-container'>
                    <img src={tarifs} alt="tarifs des licences de hockey sur glace" />
                </div>
                <div div className='card__img-container'>
                    <img src={echeancier} alt="echeancier de paiement des licences de hockey sur glace" />
                </div>
                <h2 className='card__title'>Comment faire</h2>
                <p>
                    Remplissez le formulaire d’incription ci dessous. Nous vous recontacterons par mail rapidement en vous confirmant le montant de la cotisation de votre cas particulier.
                </p>
                <p>
                    L’inscription est confirmée une fois la cotisation reçue par virement, par chèque(s), ou sur présentation d’une preuve de virements programmées.
                </p>
                <p>
                    Les documents officiels seront à compléter au plus tard cet été.
                    La cotisation est payable en 1x ou en 3x au choix
                    Le Metz Hockey Club a une préférence pour les règlements par virement bancaire via les coordonnées bancaires ci dessous. Toujours mentionner le nom du licencié et le motif du virement (ex: “Arnaud Dupont – Licence 2023”) dans le commentaire
                </p>
                <p>
                    IBAN FR76 1027 8050 1300 0208 8750 127
                </p>
                <p>
                    BIC CMCIFR2A
                </p>
                <h2 className='card__title'>Formulaire d'inscription</h2>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdCpH4OcmPmIGLhnluEnSG6fiA_tQaBlmgzyksXkqGICJaDvw/viewform?embedded=true" title="devenir-licencie" width="100%" height="2800" frameBorder="0"></iframe>
            </main>
        </Page>
    );
}