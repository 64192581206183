import pictureBand from "../images/enchere.jpg"
import maillot from "../images/maillot-enchere.jpg"
import Page from '../layouts/Page';

export default function Calendrier() {

    return (
        <Page titlePage="Vente aux enchères" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Faites vos jeux !</h2>
                <p>Pour participer à notre vente aux enchères, cliquez sur le bouton "Enchérir" ! </p>
                <p><span className="text-bold">Attention</span>, la première mise est de 50€ minimum. Pensez à regarder dans le tableau des joueurs ci-dessous quel est le montant de la <span className="text-bold"> dernière mise enchérie </span> afin de pouvoir surenchérir !</p>
                <img src= {maillot} alt="maillot de ventes aux enchères"/>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSer2NUb6-P6CcVHg6I18Mt_7q1VqbHTSAVL50Ymk2aAZNK3rQ/viewform?usp=sf_link" className="button button--link margin-bottom-30" target="_blank" rel="noreferrer">Enchérir</a>
                <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQjSJqhwFQ0dOQKzqkY4g78K63w-IdZFIqFtrbSa9MfZVBxFguMFTeexfpDfHXta_jqFJs3gO7tvjg5/pubhtml?gid=1089409488&single=false&widget=false&headers=false&chrome=false" title="vente aux enchères MHC" width="100%" height="5290px"></iframe>
            </main>
        </Page>
    );
}