import PageHeading from '../components/PageHeading'
import Sidebar from '../components/Sidebar'

export default function Page(props) {

    return (
        <>
            <PageHeading title={props.titlePage} />
            <div className="picture-band">
                <img src={props.pictureBand} className="picture-band__img" alt="metz hockey club" />
            </div>
            <div className="max-content page-content">
                {props.children}
                <Sidebar />
            </div >
        </>
    );
}