import React, { useEffect, useRef, useState } from 'react';

const FacebookPostsViewer = ({ pageId }) => {
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  let fbContainer = null;

  useEffect(() => {
    setIsLoading(true);
    if (!fbContainer) {
      fbContainer = document.createElement('div');
      fbContainer.className = 'fb-page';
      fbContainer.setAttribute('data-href', `https://www.facebook.com/${pageId}`);
      fbContainer.setAttribute('data-tabs', 'timeline');
      fbContainer.setAttribute('data-width', '376px');
      fbContainer.setAttribute('data-height', '');
      fbContainer.setAttribute('data-small-header', 'false');
      fbContainer.setAttribute('data-adapt-container-width', 'true');
      fbContainer.setAttribute('data-hide-cover', 'false');
      fbContainer.setAttribute('data-show-facepile', 'true');

      if (containerRef.current) {
        containerRef.current.appendChild(fbContainer);

        if (typeof window.FB !== 'undefined') {
          window.FB.XFBML.parse();
          window.FB.Event.subscribe('xfbml.render', () => {
            setIsLoading(false);
          });
        } else {
          (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            const js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0';
            js.onload = () => {
              window.FB.Event.subscribe('xfbml.render', () => {
                setIsLoading(false);
              });
            };
            fjs.parentNode.insertBefore(js, fjs);
          })(document, 'script', 'facebook-jssdk');
        }
      }
    }
  }, [pageId]);

  return (
    <div className="facebook-posts">
      <div className="widget-title">
        Communauté MHC
      </div>
      <div className="facebook-container">
        {isLoading && (
          <div className="facebook-skeleton">
            <div className="facebook-skeleton-header"></div>
            <div className="facebook-skeleton-content">
              <div className="facebook-skeleton-line"></div>
              <div className="facebook-skeleton-line"></div>
              <div className="facebook-skeleton-line"></div>
            </div>
          </div>
        )}
        <div
          ref={containerRef}
          className="facebook-posts-container"
          style={{ opacity: isLoading ? 0 : 1 }}
        />
      </div>
    </div>
  );
};

export default FacebookPostsViewer;