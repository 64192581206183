import pictureBand from "../images/partner.webp"
import Page from '../layouts/Page';
import bauer from '../images/partenaires/bauer.jpg'
import bodyhit from '../images/partenaires/bodyhit.png'
import burgerking from '../images/partenaires/burger-king.jpg'
import ford from '../images/partenaires/ford.jpg'
import grandest from '../images/partenaires/grand-est.jpg'
import hld from '../images/partenaires/hld.jpg'
import hospitalhome from '../images/partenaires/hospital-home.jpg'
import icearena from '../images/partenaires/icearena.jpg'
import jackelec from '../images/partenaires/jackelec.jpg'
import ldlc from '../images/partenaires/LDLC.jpg'
import metz from '../images/partenaires/metz.jpg'
import moselle from '../images/partenaires/moselle.jpg'
import moretto from '../images/partenaires/moretto.jpg'
import salmon from '../images/partenaires/salmon.jpg'
import synerglace from '../images/partenaires/synerglace.jpg'
import uem from '../images/partenaires/uem.jpg'

export default function Partenariat() {

    return (
        <Page titlePage="Partenariat" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Partenaires majeurs</h2>
                <div className='card__row'>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={salmon} alt="entreprise salmon" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={synerglace} alt="synerglace" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={icearena} alt="patinoire ice arena metz" />
                    </div>
                </div>
                <h2 className='card__title'>Partenaires insitutionnels</h2>
                <div className='card__row'>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={metz} alt="ville de metz" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={grandest} alt="région grand est" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={moselle} alt="département de la moselle" />
                    </div>
                </div>
                <h2 className='card__title'>Partenaires officiels</h2>
                <div className='card__row'>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={ford} alt="ford" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={moretto} alt="moretto" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={burgerking} alt="burger king" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={uem} alt="uem" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={ldlc} alt="ldlc" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={hospitalhome} alt="hospital home" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={jackelec} alt="jackelec" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={bodyhit} alt="bodyhit" />
                    </div>
                </div>
                <h2 className='card__title'>Fournisseurs officiels</h2>
                <div className='card__row'>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={bauer} alt="bauer" />
                    </div>
                    <div className='card__img__container card__img__container--partner'>
                        <img src={hld} alt="hld" />
                    </div>
                </div>
                <p>
                    Vous souhaitez devenir partenaire du Metz Hockey club, contactez-nous 👇
                    <br />
                     par mail
                    <br />
                    <a href="mailto:metzhockeyclub@gmail.com">metzhockeyclub@gmail.com</a>
                    <br />
                    ou par téléphone
                    <br />
                    <a href="tel:0662767300">06 62 76 73 00</a>
                </p>
            </main>
        </Page>
    );
}