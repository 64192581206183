import pictureBand from "../images/mhc-2.webp"
import Page from '../layouts/Page';

export default function AboutUs() {

    return (
        <Page titlePage="Qui sommes nous ?" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Metz Hockey Club</h2>
                <p>
                    Trouvant ses origines dès 1968, le Metz Hockey Club renait sous sa forme actuelle en septembre 2018 sous la présidence de Christophe FONDADOUZE.
                </p>
                <p>
                    Son équipe première évolue en Division 3 du Championnat de France.
                    Elle joue ses matchs à domicile à la patinoire Ice Arena de Metz.
                    Elle y affronte pendant la saison régulière des adversaires venant de tout le 1/4 Nord-Est de la France.
                    Comme beaucoup de sports collectifs américains, l’objectif de la saison régulière est de pouvoir se qualifier pour les Play-Off, une sorte de coupe à élimination directe face à des adversaires venant des 4 coins de la France.
                    Les 2 finalistes des Play-Offs gagnent leur ticket pour l’accès à la division supérieure.
                </p>
                <p>
                    Inspirés par leurs ainés, les jeunes Hockeyeurs et Hockeyeuses sont de plus en plus nombreux à venir grossir les rangs des formations des U7 aux U17.
                    Leur développement est assuré par un entraineur professionnel entouré d’une équipe pédagogique expérimentée.
                    Le Hockey sur Glace est un sport accessible dès l’age de 4 ans.
                </p>
                <p>
                    Les amateurs plus âgés ne sont d’ailleurs pas en reste puisque, contaminés par l’émulation des plus jeunes, ils se sont vu offrir une équipe Fun-Loisir, tout spécialement fondée afin de leur permettre de se retrouver sur la glace pour un moment de convivialité et de détente.
                    Les novices y sont aussi les bienvenus. Il est tout à fait posssible d’apprendre le Hockey sur Glace à tout âge.
                </p>
                <p>
                    Notre association sportive compte aujourd’hui environ 150 licenciés.
                </p>
            </main>
        </Page>
    );
}