import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

export const PageTransition = ({ children }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div
      key={location.pathname}
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 100 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 20
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};