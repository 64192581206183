import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faHockeyPuck, faPersonSkating } from '@fortawesome/free-solid-svg-icons'

export default function Headband() {
    return (
        <div className="headband">
            <div className="headband__background">
                <div className="effect-duotone__layer">
                    <div className="effect-duotone__layer-inner">
                    </div>
                </div>
            </div>
            <div className="info-block max-content">
                <ul className="info-block__social">
                    <li className="info-block__social-item">
                        <div className="info-block__social-icon" loading="lazy">
                            <FontAwesomeIcon icon={faFacebook} />
                        </div>
                        <div className="info-block__social-text-container">
                            <span className="info-block__social-title">Facebook</span>
                            <a className="info-block__social-link" href="https://facebook.com/danfisher.dev/">/MetzHockeyClub</a>
                        </div>
                    </li>
                    <li className="info-block__social-item">
                        <div className="info-block__social-icon" loading="lazy">
                            <FontAwesomeIcon icon={faTwitter} />
                        </div>
                        <div className="info-block__social-text-container">
                            <span className="info-block__social-title">Twitter</span>
                            <a className="info-block__social-link" href="https://facebook.com/danfisher.dev/">/@MetzHockeyClub</a>
                        </div>
                    </li>
                    <li className="info-block__social-item">
                        <div className="info-block__social-icon" loading="lazy">
                            <FontAwesomeIcon icon={faInstagram} />
                        </div>
                        <div className="info-block__social-text-container">
                            <span className="info-block__social-title">Instagram</span>
                            <a className="info-block__social-link" href="https://facebook.com/danfisher.dev/">@MetzHockeyClub</a>
                        </div>
                    </li>
                </ul>
                <ul className="info-block__contact">
                    <li className="info-block__contact-item">
                        <div className="info-block__contact-icon" loading="lazy">
                            <FontAwesomeIcon icon={faHockeyPuck} />
                        </div>
                        <div className="info-block__contact-text-container">
                            <span className="info-block__contact-title">Devenez partenaire !</span>
                            <a className="info-block__contact-link" href="mailto:metzhockeyclub@gmail.com">@MetzHockeyClub</a>
                        </div>
                    </li>
                    <li className="info-block__contact-item">
                        <div className="info-block__contact-icon" loading="lazy">
                            <FontAwesomeIcon icon={faPersonSkating} />
                        </div>
                        <div className="info-block__contact-text-container">
                            <span className="info-block__contact-title">Contactez nous</span>
                            <a className="info-block__contact-link" href="mailto:metzhockeyclub@gmail.com">@MetzHockeyClub</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}