export default function Maintenance() {
    return (
        <div className="maintenance">
            <div>
                🚧 Site en maintenance 🚧
            </div>
            <div>
                Nous serons de retour très bientôt !
            </div>
            <div>La boutique reste accessible 👉
                <a href="https://www.helloasso.com/associations/metz-hockey-club/boutiques/shop-officiel-metz-hockey-club-2" target="_blank" rel="noreferrer">
                    ici
                </a>
                👈
            </div>
            <div>
                Vous pouvez également nous contacter par mail : 
                <br/>
                <a href="mailto:metzhockeyclub@gmail.com">
                    metzhockeyclub@gmail.com
                </a>
            </div>
            🏒 Sportivement 🏒
        </div>
    )
}