import pictureBand from "../images/mhc-1.webp"
import Page from '../layouts/Page';
import u15 from "../pdf/Calendrier-U15-2024-2025.pdf"
import u13 from "../pdf/Calendrier-U13-2024-2025.pdf"
import u11 from "../pdf/Calendrier-U11-2024-2025.pdf"
import u9 from "../pdf/Calendrier-U9-2024-2025.pdf"
import u7 from "../pdf/Calendrier-U7-2024-2025.pdf"

export default function Calendrier() {

    return (
        <Page titlePage="Calendriers" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Calendriers 2024 / 2025</h2>
                <p>Retrouvez ici les calendriers des catégories allant de U7 à U15.</p>
                <div className="card__column">
                    <a href={u15} className="button button--link" target="_blank" rel="noreferrer">Calendrier U15</a>
                    <a href={u13} className="button button--link" target="_blank" rel="noreferrer">Calendrier U13</a>
                    <a href={u11} className="button button--link" target="_blank" rel="noreferrer">Calendrier U11</a>
                    <a href={u9} className="button button--link" target="_blank" rel="noreferrer">Calendrier U9</a>
                    <a href={u7} className="button button--link" target="_blank" rel="noreferrer">Calendrier U7</a>
                </div>
            </main>
        </Page>
    );
}