import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import FacebookPostsViewer from '../components/FacebookPostsViewer';

export default function Sidebar() {
    return (
        <aside className='sidebar'>
            <a href="https://www.facebook.com/MetzHockeyClub" className="button button--facebook" target="_blank" rel="noreferrer">
                <div className="button__icon">
                    <FontAwesomeIcon icon={faFacebook} />
                </div>
                <span className="button__title">Aimez notre page Facebook</span>
                <span className="button__subtitle">
                    Rejoignez nous
                </span>
                <span className="button__add-icon"></span>
            </a>
            <a href="https://www.facebook.com/MetzHockeyClub" className="button button--twitter" target="_blank" rel="noreferrer">
                <div className="button__icon">
                    <FontAwesomeIcon icon={faTwitter} />
                </div>
                <span className="button__title">Suivez nous sur twitter</span>
                <span className="button__subtitle">
                    Obtenez les dernières nouvelles
                </span>
                <span className="button__add-icon"></span>
            </a>
            <a href="https://www.facebook.com/MetzHockeyClub" className="button button--instagram" target="_blank" rel="noreferrer">
                <div className="button__icon">
                    <FontAwesomeIcon icon={faInstagram} />
                </div>
                <span className="button__title">Suivez nous sur instagram</span>
                <span className="button__subtitle">
                    Soyez notifiés
                </span>
                <span className="button__add-icon"></span>
            </a>
            <FacebookPostsViewer pageId="289717558437345"/>
        </aside>
    )
}