import pictureBand from "../images/mhc-3.webp"
import team from "../images/mhc-3.webp"
import Page from '../layouts/Page';

export default function EquipeDivision() {

    return (
        <Page titlePage="D3" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                    <h2 className='card__title'>L'équipe de D3</h2>
                    <div className='card__img-container'>
                        <img src={team} alt="equipe de division 3 du metz hockey club" />
                    </div>
                </main>
        </Page>
    );
}