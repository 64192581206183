import pictureBand from "../images/mhc-8.webp"
import pictureEquipment from "../images/equipement.webp"
import Page from '../layouts/Page';

export default function LocationMateriel() {

    return (
        <Page titlePage="Location matériel enfant" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Pourquoi ?</h2>
                <ul className="card__list">
                    <li className="card__list-item">Vous voulez louer le matériel adapté à votre enfant pour la nouvelle saison 2024 / 2025.</li>
                    <li className="card__list-item">Vous voulez rendre le matériel de la saison précédente que votre enfant à utilisé pendant les entraînements estivaux et récupérer ainsi votre chèque de caution?</li>
                    <li className="card__list-item">Vous voulez faire les 2 actions ci dessus en même temps ?</li>
                </ul>
                <h2 className='card__title'>Quel matériel peut être loué ?</h2>
                <div className='card__row'>
                    <p>
                        Le Metz Hockey Club propose des packs de location comprenant Patins, Casque, Gants Jambières, Coudières, Epaulières et Culotte. Le tarif pour une location à l’année est de 100€ (+ un chèque de caution de 150€ qui est restitué en fin de location).
                        Tout ce qui est textile ne fait pas partie du pack de location et doit être acheté à part : quatre pattes, tour de cou, bas, coquille de protection avec porte jarretelle.
                        Pour les premiers entraînements, il est préférable de ne pas acheter de crosse et d’attendre les conseils de l’entraîneur. Une crosse sera prêtée par le club au début de chaque séance en attendant.
                    </p>
                    <div className='card__row__img'>
                        <img src={pictureEquipment} alt="matériel de hockey" />
                    </div>
                </div>
                <p>
                    Vous souhaitez un rendez-vous pour une location de matériel, contactez-nous par mail : metzhockeyclub@gmail.com
                </p>
            </main>
        </Page>
    );
}