import PageHeading from '../components/PageHeading'
import Sidebar from '../components/Sidebar'
import { useState, useEffect } from 'react'

const STRAPI_URL = process.env.REACT_APP_STRAPI_URL || "http://localhost:1337";

const fetchJson = (collection) => {
    const url = `${STRAPI_URL}/api/${collection}?populate=*`
    return fetch(url)
        .then(response => {
            return response.json()
        }).then(response => {
            return response
        })
}

export default function StaffPage(props) {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        fetchJson(props.pageName)
            .then((response) => {
                setData(response.data)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
            })
    }, [props.pageName])

    const SkeletonStaff = () => (
        <>
            {[1, 2, 3].map((item) => (
                <article className="staff__item skeleton" key={`skeleton-${item}`}>
                    <div className="staff__informations">
                        <span className="staff__firstname skeleton-text"></span>
                        <span className="staff__lastname skeleton-text"></span>
                        <span className="staff__function skeleton-text"></span>
                    </div>
                    <div className="staff__picture skeleton-image"></div>
                </article>
            ))}
        </>
    )

    return (
        <>
            <PageHeading title={props.pageTitle} />
            <div className="max-content page-content">
                <main className="main-content">
                    <div className={props.isSmall ? "staff staff--small" : "staff"}>
                        {isLoading ? (
                            <SkeletonStaff />
                        ) : (
                            data?.map((people, index) => (
                                <article className="staff__item" key={"staff-" + index}>
                                    <div className="staff__informations">
                                        <span className="staff__firstname">{people.prenom}</span>
                                        <span className="staff__lastname">{people.nom}</span>
                                        {people.fonction &&
                                            <span className="staff__function">{people.fonction}</span>
                                        }
                                    </div>
                                    <div className='staff__picture'>
                                        <img src={STRAPI_URL + people.photo.url} alt="staff" />
                                    </div>
                                </article>
                            ))
                        )}
                    </div>
                </main>
                <Sidebar />
            </div>
        </>
    );
}