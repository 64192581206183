import pictureBand from "../images/mhc-8.webp"
import pictureInscription1 from "../images/Inscription-Graoully-Cup-2024-U13_1.webp"
import pictureInscription2 from "../images/Inscription-Graoully-Cup-2024-U13_2.webp"
import Page from '../layouts/Page';

export default function Tournoi() {

    return (
        <Page titlePage="Tournoi U13" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>La Graoully Cup U13</h2>
                <p>
                    La Graoully Cup U13 est un tournoi U13 qui aura lieu le WE du 20/21 Mai 2023 à la patinoire de Metz.
                    Entre 8 et 10 équipes se rencontreront à 5 contre 5 sur grande glace. Le tournoi a la particularité
                    de se dérouler à proximités immédiates des frontières allemande, belge et luxembourgeoise favorisant
                    la rencontre de clubs de tout horizon et différentes nationalités.
                </p>
                <p>
                    The Graoully Cup U13 is a U13 tournament organized on 20th/21st May 2023 at Metz ice rink.
                    From 8 to 10 teams will compete each other through 5vs.5 full-ice games. This tournament is
                    special in the way its geographical location, immediately close to the german, belgium and
                    luxembourguish borders, helps to gather different teams coming from different cultures and
                    countries.
                </p>
                <h2 className='card__title'>La plaquette</h2>
                <div className='card__img-container'>
                    <img src={pictureInscription1} alt="matériel de hockey" />
                </div>
                <div className='card__img-container'>
                    <img src={pictureInscription2} alt="matériel de hockey" />
                </div>
                <h2 className='card__title'>Formulaire de pré-inscription</h2>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScd0I6UCrJuOoaXz5uU6nxT5OpsuvR2V5OC49cFrlwki0Bu7A/viewform?embedded=true" frameBorder="0"  width="100%" height="1800" title="formulaire de pré inscription tournoi U13"></iframe>
            </main>
        </Page>
    );
}