import Header from "./Header";
import Footer from './Footer';
import ScrollToTop from '../utils/ScrollToTop';
import { Outlet} from "react-router-dom";

export const MainLayout = () => {
    const isMaintenancePage = window.location.pathname === '/maintenance';

    return (
        <>
            {!isMaintenancePage && <Header />}
        
                <Outlet />
           
            <ScrollToTop />
            {!isMaintenancePage && <Footer />}
        </>
    );
};