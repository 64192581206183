import Headband from '../components/Headband'
import Nav from '../components/Nav'
import logo from '../images/logo-metz-hockey-club.png'
import { Link } from 'react-router-dom'
import { useState } from 'react'

export default function Header() {
    const [isOverlay, setIsOverlay] = useState(false);

    // hideNavMobile
    return (
        <header className="header">
            <Headband />
            <Nav isOverlayVisible={isOverlay} hideNavMobile={() => setIsOverlay(false)} />
            <div className="header-mobile">
                <div className="header-mobile__logo">
                    <Link to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                <div className="burger-menu-icon" onClick={() => setIsOverlay(!isOverlay)}>
                    <span className="burger-menu-icon__line"></span>
                </div>
            </div>
        </header>
    )
}