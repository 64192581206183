import pictureBand from "../images/privacy.webp"
import Page from '../layouts/Page'

export default function PrivacyPolicy() {

    return (
        <Page titlePage="Protection des données" pictureBand={pictureBand}>
            <main className="card card--on-banner">
                <h2 className='card__title'>Qui sommes nous ?</h2>
                <p>
                    Le Metz Hockey Club <br/>
                    Notre adresse de site Web est : www.metz-hockey.fr <br/>
                    Adresse : Patinoire Ice Arena – 4 boulevard Saint-Symphorien – 57050 Longeville-Les-Metz, France. <br/>
                    Téléphone : 06 60 93 13 06 <br/>
                    Mail : metzhockeyclub@gmail.com
                </p>
                <h2 className='card__title'>Principes applicables à la protection des données personnelles</h2>
                <p>
                    Le Metz Hockey Club traite les données personnelles dans le respect des lois et réglementations en vigueur, et notamment du Règlement Général sur la Protection des Données (RGPD), de la loi Informatique et Libertés du 6 janvier 1978 modifiée et des référentiels édictés par la CNIL.<br/>
                    <br/>
                    Le Metz Hockey Club s’engage, dans le cadre des missions qui lui sont confiées, à assurer la protection, la confidentialité et la sécurité de l’ensemble des données personnelles.
                    Sur l’ensemble de nos traitements, nous appliquons la présente politique de protection des données personnelles. Celle-ci nous permet de vous informer sur la protection de vos données.
                    La présente politique témoigne des engagements mis en œuvre dans le cadre des activités quotidiennes pour une utilisation responsable des données personnelles.
                </p>
                <h2 className='card__title'>Un délégué à la protection des données (DPO)</h2>
                <p>
                    Afin de préserver la vie privée et la protection des données à caractère personnel de tous, Le Metz Hockey Club a désigné un Délégué à la Protection des données.
                    <br/>
                    Le Metz Hockey Club<br/>
                    06 60 93 13 06<br/>
                    metzhockeyclub@gmail.com<br/>
                    Patinoire Ice Arena – 4 boulevard Saint-Symphorien – 57050 Longeville-Les-Metz, France.<br/>
                    <br/>
                    C’est auprès de lui que peut s’exercer votre droit d’accès aux informations traitées.
                </p>
                <h2 className='card__title'>Finalité déterminée, explicite et légitime du traitement</h2>
                <p>
                    Les données personnelles sont collectées pour des objectifs précis (finalités), portés à la connaissance des personnes concernées. Ces données ne peuvent être utilisées ultérieurement de manière incompatible avec ces finalités. <br/>
                    Ces données sont collectées loyalement ; aucune collecte n’est effectuée à l’insu des personnes et sans qu’elles en soient informées.
                    <br/><br/>
                    Les données personnelles collectées sont strictement nécessaires à l’objectif poursuivi par la collecte. Le Metz Hockey Club s’attache à minimiser les données collectées, à les tenir exactes et à jour en facilitant les droits des personnes concernées.
                    <br/><br/>
                    – Formulaire de contact
                    <br/><br/>
                    Si vous utilisez le formulaire de contact du site, il vous sera demandé de laisser votre nom, prénom, numéro de téléphone et e-mail. Ces données ne seront pas transmises mais pourront être utilisées par Le Metz Hockey Club uniquement pour vous contacter et faire suite à votre demande.
                    <br/><br/>
                    – Communiquer avec vous.
                    <br/><br/>
                    Nous utilisons vos informations personnelles afin de communiquer avec vous au sujet des Services Le Metz Hockey Club via différents canaux (ex. téléphone, email, chat).
                    Nous pourrons également être amenés à requérir votre consentement pour le traitement de vos informations personnelles pour une finalité spécifique que nous vous indiquerons. Lorsque vous consentez au traitement de vos informations personnelles pour cette finalité spécifique, vous pourrez retirer votre consentement à tout moment et nous cesserons tout traitement de vos informations pour cette finalité.
                </p>
                <h2 className='card__title'>Confidentialité et sécurité des données</h2>
                <p>
                    Toutes les précautions utiles sont prises pour assurer la sécurité et la confidentialité de vos données personnelles, notamment pour empêcher leur perte, altération, destruction ou utilisation par des tiers non autorisés.
                    <br/><br/>
                    Le Metz Hockey Club s’assure que tout sous-traitant présente des garanties appropriées pour assurer la sécurité et la confidentialité des données personnelles.
                    <br/><br/>
                    Par ailleurs, certaines administrations peuvent solliciter Le Metz Hockey Club pour le transfert de données personnelles dans le cadre du droit de communication. Ces cas de figure sont explicitement prévus par la loi.
                    <br/><br/>
                    Toutes les personnes ayant accès aux données personnelles sont liées par un devoir de confidentialité et s’exposent à des mesures disciplinaires et/ou autres sanctions si elles ne respectent pas ces obligations.
                </p>
                <h2 className='card__title'>Durée de conservation limitée des données à caractère personnel</h2>
                <p>
                    Les données personnelles détenues par Le Metz Hockey Club sont conservées pendant un délai maximum de douze mois.
                </p>
                <h2 className='card__title'>Droit des personnes</h2>
                <p>
                    Le Metz Hockey Club met en œuvre les moyens nécessaires pour informer toute personne qui en fait la demande de l’existence de données personnelles qui la concernent et de l’usage qui en est fait.
                    <br/><br/>
                    À cet effet, vous disposez d’un droit d’accès et de rectification (données inexactes, incomplètes, etc.) aux données qui vous concernent ainsi que, dans certains cas, d’un droit à leur limitation ou d’effacement.
                    Conformément au RGPD, Le Metz Hockey Club se réserve le droit de demander le paiement de frais raisonnables qui tiennent compte des coûts administratifs supportés pour fournir les informations, procéder aux communications ou prendre les mesures demandées, ainsi que le droit de refuser de répondre à toute demande manifestement infondée ou excessive, en vous informant des motifs du refus.
                    <br/><br/>
                    Ces droits s’exercent auprès du Dirigeant Du Metz Hockey Club en contactant le délégué à la protection des données via :
                    <br/><br/>
                    metzhockeyclub@gmail.com
                    <br/><br/>
                    En cas de difficultés dans l’application des droits énoncés ci-dessus, toute personne peut introduire une réclamation auprès de la Cnil : Commission nationale de l’informatique et des libertés – 3, place de Fontenoy – TSA 80715 – 75334 Paris cedex 07.
                </p>
                <h2 className='card__title'>Sous-traitance</h2>
                <p>
                    Le Metz Hockey Club pourra faire intervenir tout sous-traitant de son choix dans le cadre du traitement de ses données à caractère personnel. Elle exige également de son sous-traitant qu’il présente des garanties appropriées pour assurer la sécurité et la confidentialité des données personnelles et s’assure du respect par le sous-traitant de ses obligations au regard du RGPD.
                </p>
                <h2 className='card__title'>Utilisation de cookies</h2>
                <p>
                    Lors de la consultation du site Le Metz Hockey Club, des cookies, traceurs et/ou témoins de connexion sont déposés sur votre ordinateur, votre tablette ou votre smartphone, sous réserve des choix et options que vous avez exprimés ou pouvez exprimer à tout moment conformément à la « Politique cookies ».
                </p>
            </main>
        </Page>
    );
}