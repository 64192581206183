import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MainLayout } from '../layouts/MainLayout';
import { routes } from './Routes';

export const RouterConfig = () => {
  const router = createBrowserRouter([
    {
      element: <MainLayout />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} />;
};