import Sidebar from "../components/Sidebar"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import 'swiper/css';
import partner1 from '../images/partenaires/bauer.jpg'
import partner2 from '../images/partenaires/bodyhit.png'
import partner3 from '../images/partenaires/burger-king.jpg'
import partner4 from '../images/partenaires/ffhg.jpg'
import partner5 from '../images/partenaires/ford.jpg'
import partner6 from '../images/partenaires/grand-est.jpg'
import partner7 from '../images/partenaires/hld.jpg'
import partner8 from '../images/partenaires/hospital-home.jpg'
import partner9 from '../images/partenaires/icearena.jpg'
import partner10 from '../images/partenaires/jackelec.jpg'
import partner11 from '../images/partenaires/LDLC.jpg'
import partner12 from '../images/partenaires/metz.jpg'
import partner13 from '../images/partenaires/moise.jpg'
import partner14 from '../images/partenaires/moretto.jpg'
import partner15 from '../images/partenaires/salmon.jpg'
import partner16 from '../images/partenaires/synerglace.jpg'
import partner17 from '../images/partenaires/uem.jpg'
import hero1 from '../images/mhc-10.webp'
import hero2 from '../images/mhc-9.webp'
import hero3 from '../images/mhc-11.webp'
import hero4 from '../images/mhc-12.webp'
import hero5 from '../images/mhc-7.webp'

function Homepage() {
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <>
      <div className="max-content hero-gallery">
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.realIndex);
          }}
          modules={[Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          loop={true}
          slidesPerView={1}
          className="hero-gallery-main"
        >
          <SwiperSlide className="hero-gallery-main__item">
            <Link className="hero-gallery-main__item-link" to="/devenir-licencie">
              <img src={hero1} alt="" />
              <div className="hero-gallery-main__item-cta"></div>
              <div className="hero-gallery-main__item-text">
                <div className="hero-gallery-main__item-label">
                  Licence
                </div>
                <span>Les inscriptions pour l'année 2024-2025 sont ouvertes !</span>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="hero-gallery-main__item">
            <Link className="hero-gallery-main__item-link" to="/devenir-licencie">
              <img src={hero2} alt="" />
              <div className="hero-gallery-main__item-cta"></div>
              <div className="hero-gallery-main__item-text">
                <div className="hero-gallery-main__item-label">
                  Boutique
                </div>
                <span>Visitez la boutique en ligne du Metz Hockey Club !</span>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="hero-gallery-main__item">
            <Link className="hero-gallery-main__item-link" to="/partenariat">
              <img src={hero3} alt="" />
              <div className="hero-gallery-main__item-cta"></div>
              <div className="hero-gallery-main__item-text">
                <div className="hero-gallery-main__item-label">
                  Partenariat
                </div>
                <span>Devenez partenaire du Metz Hockey Club !</span>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="hero-gallery-main__item">
            <Link className="hero-gallery-main__item-link" to="/location-materiel">
              <img src={hero4} alt="" />
              <div className="hero-gallery-main__item-cta"></div>
              <div className="hero-gallery-main__item-text">
                <div className="hero-gallery-main__item-label">
                  Location
                </div>
                <span>Le MHC vous propose la location de matériel de hockey pour votre enfant !</span>
              </div>
            </Link>
          </SwiperSlide>
          <SwiperSlide className="hero-gallery-main__item">
            <Link className="hero-gallery-main__item-link" to="/qui-sommes-nous">
              <img src={hero5} alt="" />
              <div className="hero-gallery-main__item-cta"></div>
              <div className="hero-gallery-main__item-text">
                <div className="hero-gallery-main__item-label">
                  Histoire
                </div>
                <span>Envie d'en savoir un peu plus sur l'histoire du MHC ?</span>
              </div>
            </Link>
          </SwiperSlide>
        </Swiper>
        <div className="hero-gallery-nav">
          <div className={`hero-gallery-nav__item ${activeIndex === 0 ? 'active' : ''}`} onClick={() => swiperRef.current.slideTo(0)}>
            <img src={hero1} alt="" />
            <div className="hero-gallery-nav__item-text">
              <div className="hero-gallery-nav__item-label">
                Licence
              </div>
              <span>Les inscriptions pour l'année 2024-2025 sont ouvertes !</span>
            </div>
          </div>
          <div className={`hero-gallery-nav__item ${activeIndex === 1 ? 'active' : ''}`} onClick={() => swiperRef.current.slideTo(1)}>
            <img src={hero2} alt="" />
            <div className="hero-gallery-nav__item-text">
              <div className="hero-gallery-nav__item-label">
                Boutique
              </div>
              <span>Visitez la boutique en ligne du Metz Hockey Club !</span>
            </div>
          </div>
          <div className={`hero-gallery-nav__item ${activeIndex === 2 ? 'active' : ''}`} onClick={() => swiperRef.current.slideTo(2)}>
            <img src={hero3} alt="" />
            <div className="hero-gallery-nav__item-text">
              <div className="hero-gallery-nav__item-label">
                Partenariat
              </div>
              <span>Devenez partenaire du Metz Hockey Club !</span>
            </div>
          </div>
          <div className={`hero-gallery-nav__item ${activeIndex === 3 ? 'active' : ''}`} onClick={() => swiperRef.current.slideTo(3)}>
            <img src={hero4} alt="" />
            <div className="hero-gallery-nav__item-text">
              <div className="hero-gallery-nav__item-label">
                Location
              </div>
              <span>Le MHC vous propose la location de matériel de hockey pour votre enfant !</span>
            </div>
          </div>
          <div className={`hero-gallery-nav__item ${activeIndex === 4 ? 'active' : ''}`} onClick={() => swiperRef.current.slideTo(4)}>
            <img src={hero5} alt="" />
            <div className="hero-gallery-nav__item-text">
              <div className="hero-gallery-nav__item-label">
                Histoire
              </div>
              <span>Envie d'en savoir un peu plus sur l'histoire du MHC ?</span>
            </div>
          </div>
        </div>
      </div>
      <div className="max-content page-content">
        <div className="page-content__container">
          <div className="card">
            <h1 className="card__big-title">Bienvenue sur le site officiel du MHC</h1>
            <p>Découvrez tout ce que notre club a à offrir aux passionnés de hockey sur glace, qu’ils soient débutants ou fervents supporters.</p>
            <h2 className="card__title">Rejoignez l'aventure sur la glace !</h2>
            <p>
              Envie de vous initier ou de progresser en hockey sur glace ?
              Nos cours sont ouverts à tous les âges et tous les niveaux. Notre entraîneur qualifié vous accompagnera pour faire de vous un véritable joueur de hockey.
              N’attendez plus, inscrivez-vous dès aujourd’hui et vivez la passion du hockey sur glace !
            </p>
            <Link to="/devenir-licencie" className="button button--link">inscrivez-vous</Link>
            <h2 className="card__title">Soutenez notre équipe de Division 3 !</h2>
            <p>
              Suivez les exploits de notre équipe de D3 tout au long de la saison ! Ensemble, faisons vibrer la patinoire et soutenons les Graoullys !
            </p>
            <a href="https://www.facebook.com/MetzHockeyClub" className="button button--link" target="_blank" rel="noreferrer">suivez nous</a>
            <h2 className="card__title">Découvrez notre boutique officielle !</h2>
            <p>
              Affichez fièrement les couleurs du Metz Hockey Club ! Équipez-vous avec nos produits exclusifs : maillots, écharpes, accessoires et bien plus. Faites un tour dans notre boutique en ligne et soutenez le club avec style.
            </p>
            <a href="https://www.helloasso.com/associations/metz-hockey-club/boutiques/shop-officiel-metz-hockey-club-2" className="button button--link" target="_blank" rel="noreferrer">visitez la boutique</a>
            <p>
              Rejoignez la communauté du Metz Hockey Club et vivez une expérience unique au cœur du hockey sur glace. À très vite sur la glace ou dans les tribunes !
            </p>
          </div>
          <h2>Le metz hockey club peut compter sur ses partenaires</h2>
          <div >
          <Swiper
            className="partner-swiper"
            modules={[Autoplay]}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              1024: {
                slidesPerView: 4,
              },
              550: {
                slidesPerView: 3,
              },
            }}
            loop={true}
            spaceBetween={20}
            slidesPerView={1}
          >
            <SwiperSlide className="partner-swiper__slide"><img src={partner1} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner2} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner3} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner4} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner5} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner6} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner7} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner8} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner9} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner10} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner11} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner12} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner13} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner14} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner15} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner16} alt="" /></SwiperSlide>
            <SwiperSlide className="partner-swiper__slide"><img src={partner17} alt="" /></SwiperSlide>
          </Swiper>
          </div>
        </div>
        <Sidebar />
      </div >
    </>
  );
}

export default Homepage;